var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "642", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "createCampaignBox  search-result  rounded-lg",
              attrs: { elevation: "3" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "sub-heading-with-btn mb-0  d-flex align-start justify-space-between sticky-top",
                },
                [
                  _c(
                    "h5",
                    { staticClass: " primarybase--text h5--text-bold mb-3 " },
                    [_vm._v(" Add Campaign ")]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "d-flex justify-end primarybase--text close",
                      on: {
                        click: function ($event) {
                          return _vm.resetDialog()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              [
                _c(
                  "v-stepper",
                  {
                    staticClass: "pa-4 rounded-lg px-0 py-0",
                    attrs: { elevation: "0" },
                    on: {
                      change: function ($event) {
                        return _vm.stepChanged($event)
                      },
                    },
                    model: {
                      value: _vm.step,
                      callback: function ($$v) {
                        _vm.step = $$v
                      },
                      expression: "step",
                    },
                  },
                  [
                    _c(
                      "v-stepper-items",
                      [
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "1" } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "align-center" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      lg: "12",
                                      md: "12",
                                      sm: "12",
                                      cols: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "box-header chart-header-area",
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "primarybase--text body--text-1 mb-9",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("createCampaign.h2")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "mb-3",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "formStep1",
                                    model: {
                                      value: _vm.valid1,
                                      callback: function ($$v) {
                                        _vm.valid1 = $$v
                                      },
                                      expression: "valid1",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.chooseCampGroup"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    "item-text": "orgName",
                                                    "item-value": "orgId",
                                                    items:
                                                      _vm.getCampaignGroups,
                                                    placeholder: _vm.$t(
                                                      "createCampaign.chooseCamp"
                                                    ),
                                                    dense: "",
                                                    rules:
                                                      _vm.campaignGroupRules,
                                                  },
                                                  model: {
                                                    value: _vm.orgId,
                                                    callback: function ($$v) {
                                                      _vm.orgId = $$v
                                                    },
                                                    expression: "orgId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.chooseApp"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    items: _vm.appList,
                                                    "search-input": _vm.search,
                                                    dense: "",
                                                    "item-text": "appName",
                                                    "item-value": "adamId",
                                                    "cache-items": "",
                                                    placeholder: _vm.$t(
                                                      "createCampaign.selectApp"
                                                    ),
                                                    rules: _vm.appRules,
                                                    "return-object": "",
                                                    loading:
                                                      _vm.isAppSearchLoading,
                                                  },
                                                  on: {
                                                    "update:searchInput": function (
                                                      $event
                                                    ) {
                                                      _vm.search = $event
                                                    },
                                                    "update:search-input": function (
                                                      $event
                                                    ) {
                                                      _vm.search = $event
                                                    },
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item",
                                                      fn: function (data) {
                                                        return [
                                                          [
                                                            _c("v-img", {
                                                              staticClass:
                                                                "rounded-lg",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                "lazy-src":
                                                                  data.item
                                                                    .appIcon,
                                                                "max-width":
                                                                  "30",
                                                                "max-height":
                                                                  "30",
                                                                "min-width":
                                                                  "20",
                                                                "min-height":
                                                                  "20",
                                                                src:
                                                                  data.item
                                                                    .appIcon,
                                                              },
                                                            }),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .appName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                  model: {
                                                    value: _vm.selectedApp,
                                                    callback: function ($$v) {
                                                      _vm.selectedApp = $$v
                                                    },
                                                    expression: "selectedApp",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right py-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-3 mb-2 px-6 w-xsm-100",
                                        attrs: {
                                          color: "disable",
                                          outlined: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.back()
                                          },
                                        },
                                      },
                                      [_vm._v(" Back ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "w-xsm-100 mb-2 px-6",
                                        attrs: {
                                          color: "primary",
                                          depressed: "",
                                          large: "",
                                          disabled: !_vm.valid1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setProperties()
                                          },
                                        },
                                      },
                                      [_vm._v(" Continue ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center px-0 pt-2 pb-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: " primary--text" },
                                      [
                                        _vm._v("Step   "),
                                        _c("span", {}, [_vm._v("1")]),
                                        _vm._v("/7 "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "2" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "mb-3",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "align-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "12" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "box-header chart-header-area",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "primarybase--text body--text-1 mb-9",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.countPara"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-form",
                                  {
                                    ref: "formStep2",
                                    model: {
                                      value: _vm.valid2,
                                      callback: function ($$v) {
                                        _vm.valid2 = $$v
                                      },
                                      expression: "valid2",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field mb-4" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.chooseCount"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    clearable: "",
                                                    multiple: "",
                                                    placeholder: "Countries",
                                                    dense: "",
                                                    chips: "",
                                                    "small-chips": "",
                                                    "deletable-chips": "",
                                                    items: _vm.appCountries,
                                                    "item-text": "Name",
                                                    "item-value": "Code",
                                                    rules: _vm.countryRules,
                                                    "search-input":
                                                      _vm.searchInput,
                                                  },
                                                  on: {
                                                    "update:searchInput": function (
                                                      $event
                                                    ) {
                                                      _vm.searchInput = $event
                                                    },
                                                    "update:search-input": function (
                                                      $event
                                                    ) {
                                                      _vm.searchInput = $event
                                                    },
                                                    change: function ($event) {
                                                      _vm.searchInput = null
                                                    },
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item",
                                                      fn: function (data) {
                                                        return [
                                                          [
                                                            _c("country-flag", {
                                                              attrs: {
                                                                country: data.item.Code.toLowerCase(),
                                                              },
                                                            }),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .Name
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                  model: {
                                                    value:
                                                      _vm.selectedCountries,
                                                    callback: function ($$v) {
                                                      _vm.selectedCountries = $$v
                                                    },
                                                    expression:
                                                      "selectedCountries",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.campName"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    required: "",
                                                    dense: "",
                                                    rules:
                                                      _vm.campaignNameRules,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkCampaignName()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.campaignName,
                                                    callback: function ($$v) {
                                                      _vm.campaignName = $$v
                                                    },
                                                    expression: "campaignName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { staticClass: "align-center mt-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field left-icon-field-area",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.budget"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    dense: "",
                                                    "prepend-inner-icon":
                                                      "mdi-currency-usd",
                                                    rules: _vm.budgetRules,
                                                  },
                                                  model: {
                                                    value: _vm.budget,
                                                    callback: function ($$v) {
                                                      _vm.budget = $$v
                                                    },
                                                    expression: "budget",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field left-icon-field-area",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.dailyCap"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  ref: "dailyCapref",
                                                  attrs: {
                                                    dense: "",
                                                    "prepend-inner-icon":
                                                      "mdi-currency-usd",
                                                    rules: _vm.dailyCapRules,
                                                  },
                                                  model: {
                                                    value: _vm.dailyCap,
                                                    callback: function ($$v) {
                                                      _vm.dailyCap = $$v
                                                    },
                                                    expression: "dailyCap",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "text-right pa-0 clear-all-icon",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-4 w-xsm-100 mb-2 px-6",
                                        attrs: {
                                          color: "disable",
                                          outlined: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.step = 1
                                          },
                                        },
                                      },
                                      [_vm._v(" Back ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          " w-xsm-100 mr-3 px-6 mb-2",
                                        attrs: {
                                          color: "primary",
                                          elevation: "0",
                                          depressed: "",
                                          large: "",
                                          disabled:
                                            !_vm.valid2 ||
                                            _vm.savingCountriesRegions,
                                          loading: _vm.savingCountriesRegions,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveCountriesRegions()
                                          },
                                        },
                                      },
                                      [_vm._v(" Continue ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center px-0 pt-2 pb-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: " primary--text" },
                                      [
                                        _vm._v("Step   "),
                                        _c("span", {}, [_vm._v("2")]),
                                        _vm._v("/7 "),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "3" } },
                          [
                            _c(
                              "v-card",
                              { staticClass: " ", attrs: { elevation: "0" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "box-header chart-header-area",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "primarybase--text body--text-1 mb-9",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createCampaign.adgroupPara"
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-form",
                                  {
                                    ref: "formStep3",
                                    model: {
                                      value: _vm.valid3,
                                      callback: function ($$v) {
                                        _vm.valid3 = $$v
                                      },
                                      expression: "valid3",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.adgroupName"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    required: "",
                                                    dense: "",
                                                    rules: _vm.adgroupNameRules,
                                                  },
                                                  model: {
                                                    value: _vm.adGroupName,
                                                    callback: function ($$v) {
                                                      _vm.adGroupName = $$v
                                                    },
                                                    expression: "adGroupName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " py-0",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex date-pic align-center w-100 flex-column justify-start mr-4",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      " mb-3 d-flex pt-0 w-100",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.startTime"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "calendar-menu pr-4 cursor-pointer",
                                                    attrs: {
                                                      datePickerProps:
                                                        _vm.datePickerProps,
                                                      depressed: "",
                                                      height: "42",
                                                      primary: "",
                                                      block: "",
                                                    },
                                                  },
                                                  [
                                                    _vm.dialog
                                                      ? _c(
                                                          "v-datetime-picker",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "click-outside",
                                                                rawName:
                                                                  "v-click-outside",
                                                                value: {
                                                                  handler:
                                                                    _vm.onClickOutside,
                                                                  closeConditional:
                                                                    _vm.onCloseConditional,
                                                                },
                                                                expression:
                                                                  "{\n                                              handler: onClickOutside,\n                                              closeConditional: onCloseConditional\n                                            }",
                                                              },
                                                            ],
                                                            ref:
                                                              "dateTimePickerRef",
                                                            attrs: {
                                                              outlined: "",
                                                              dense: "",
                                                              datePickerProps:
                                                                _vm.datePickerProps,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.startdate,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.startdate = $$v
                                                              },
                                                              expression:
                                                                "startdate",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-calendar-clock"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " py-0",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-7",
                                                attrs: {
                                                  width: "100%",
                                                  color: "primary",
                                                  depressed: "",
                                                  large: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.expandAdScheduling = !_vm.expandAdScheduling
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.expandAdScheduling
                                                        ? "-"
                                                        : "+"
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.adScheduling"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.expandAdScheduling,
                                                expression:
                                                  "expandAdScheduling",
                                              },
                                            ],
                                            attrs: { col: "12" },
                                          },
                                          [
                                            _c("v-expand-transition", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "add-scheduling ",
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-4",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "field w-100",
                                                            },
                                                            [
                                                              _c("v-checkbox", {
                                                                staticClass:
                                                                  "mt-0",
                                                                attrs: {
                                                                  label:
                                                                    "End Time (Optional)",
                                                                  color:
                                                                    "primary",
                                                                  value: true,
                                                                  "hide-details":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.endTimeCheck,
                                                                  callback: function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.endTimeCheck = $$v
                                                                  },
                                                                  expression:
                                                                    "endTimeCheck",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "field",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "mb-3 d-flex",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createCampaign.endTime"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "calendar-menu pr-4 ",
                                                                  attrs: {
                                                                    datePickerProps:
                                                                      _vm.datePickerProps,
                                                                    disabled: !_vm.endTimeCheck,
                                                                    depressed:
                                                                      "",
                                                                    height:
                                                                      "42",
                                                                    primary: "",
                                                                    block: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.dialog
                                                                    ? _c(
                                                                        "v-datetime-picker",
                                                                        {
                                                                          attrs: {
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            datePickerProps:
                                                                              _vm.datePickerProps,
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.enddate,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.enddate = $$v
                                                                            },
                                                                            expression:
                                                                              "enddate",
                                                                          },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-calendar-clock"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "field  mb-0",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "mb-3 d-flex",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createCampaign.dayParting"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "pa-2 calendar-menu ",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    height:
                                                                      "42",
                                                                    primary: "",
                                                                    block: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      ref:
                                                                        "menu",
                                                                      attrs: {
                                                                        "close-on-content-click": false,
                                                                        "return-value":
                                                                          _vm.date,
                                                                        transition:
                                                                          "scale-transition",
                                                                        "offset-y":
                                                                          "",
                                                                        "min-width":
                                                                          "auto",
                                                                      },
                                                                      on: {
                                                                        "update:returnValue": function (
                                                                          $event
                                                                        ) {
                                                                          _vm.date = $event
                                                                        },
                                                                        "update:return-value": function (
                                                                          $event
                                                                        ) {
                                                                          _vm.date = $event
                                                                        },
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "pr-2 pl-3",
                                                                                        attrs: {
                                                                                          "append-icon":
                                                                                            "mdi-calendar",
                                                                                          readonly:
                                                                                            "",
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm.date,
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.date = $$v
                                                                                          },
                                                                                          expression:
                                                                                            "date",
                                                                                        },
                                                                                      },
                                                                                      "v-text-field",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ]
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm.menu,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.menu = $$v
                                                                        },
                                                                        expression:
                                                                          "menu",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-date-picker",
                                                                        {
                                                                          attrs: {
                                                                            "no-title":
                                                                              "",
                                                                            scrollable:
                                                                              "",
                                                                            min:
                                                                              _vm
                                                                                .datePickerProps
                                                                                .min,
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.date,
                                                                            callback: function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.date = $$v
                                                                            },
                                                                            expression:
                                                                              "date",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                text:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.menu = false
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "createCampaign.cancel"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                text:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$refs.menu.save(
                                                                                    _vm.date
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "createCampaign.ok"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      " mb-0 d-flex select-ipad-iphone",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.devices"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.Devices,
                                                    placeholder: _vm.$t(
                                                      "createCampaign.selectDev"
                                                    ),
                                                    attach: "",
                                                    dense: "",
                                                    rules: _vm.deviceRules,
                                                    multiple: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "item",
                                                      fn: function (data) {
                                                        return [
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .src
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .text
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                  model: {
                                                    value: _vm.selectDevices,
                                                    callback: function ($$v) {
                                                      _vm.selectDevices = $$v
                                                    },
                                                    expression: "selectDevices",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.defaultMax"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  ref: "cptBidRef",
                                                  attrs: {
                                                    required: "",
                                                    dense: "",
                                                    "prepend-inner-icon":
                                                      "mdi-currency-usd",
                                                    rules: _vm.cptBidRules,
                                                  },
                                                  model: {
                                                    value: _vm.defaultBidAmount,
                                                    callback: function ($$v) {
                                                      _vm.defaultBidAmount = $$v
                                                    },
                                                    expression:
                                                      "defaultBidAmount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "6",
                                              cols: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.cpaGoal"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    required: "",
                                                    dense: "",
                                                    rules: _vm.cpaGoalRules,
                                                    "prepend-inner-icon":
                                                      "mdi-currency-usd",
                                                  },
                                                  model: {
                                                    value: _vm.cpaGoal,
                                                    callback: function ($$v) {
                                                      _vm.cpaGoal = $$v
                                                    },
                                                    expression: "cpaGoal",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "my-2 mt-6 text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: { color: "disable", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 2
                                      },
                                    },
                                  },
                                  [_vm._v(" Back ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      outlined: "",
                                      large: "",
                                      disabled: _vm.savingCampaignDraft,
                                      loading: _vm.savingCampaignDraft,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAsDraft()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.saveDraft")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      large: "",
                                      disabled:
                                        !_vm.valid3 || _vm.startdate == null,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAdGroupObject()
                                      },
                                    },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-center" }, [
                              _c("span", { staticClass: " primary--text" }, [
                                _vm._v("Step   "),
                                _c("span", {}, [_vm._v("3")]),
                                _vm._v("/7 "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "4" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "mb-3",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "align-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "12" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "box-header chart-header-area",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "primarybase--text body--text-1 mb-9",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.searchMatchPara"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "match-searches d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "body-2 mb-0 mr-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.searchMatchPara1"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("v-switch", {
                                              staticClass: " ",
                                              model: {
                                                value: _vm.autoOpt,
                                                callback: function ($$v) {
                                                  _vm.autoOpt = $$v
                                                },
                                                expression: "autoOpt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "my-2 text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: { color: "disable", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 3
                                      },
                                    },
                                  },
                                  [_vm._v(" Back ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      outlined: "",
                                      large: "",
                                      disabled: _vm.savingCampaignDraft,
                                      loading: _vm.savingCampaignDraft,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAsDraft()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.saveDraft")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      large: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAutoOptIn()
                                      },
                                    },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-center" }, [
                              _c("span", { staticClass: " primary--text" }, [
                                _vm._v("Step   "),
                                _c("span", {}, [_vm._v("4")]),
                                _vm._v("/7 "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "5" } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "d-flex align-start" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "6",
                                      lg: "6",
                                      md: "6",
                                      sm: "6",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "ad-groupKeywordStep",
                                        attrs: { elevation: "0" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "primarybase--text body--text-1 mb-3 mt-6",
                                          },
                                          [
                                            _vm._v(" Ad Group Keywords "),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                class: [
                                                  !_vm.tKeys.length
                                                    ? "caption--text red--text"
                                                    : "caption--text greybase--text",
                                                  "disabled",
                                                ],
                                              },
                                              [_vm._v("Required")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-left body-2" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "data:text/csv;base64,bmFtZSxhbW91bnQsbWF0Y2gsY3VycmVuY3kKa3csMC4xLEVYQUNULFVTRAo=",
                                                  download:
                                                    "targeting-keyword-example.csv",
                                                },
                                              },
                                              [_vm._v("Example Sheet")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "upload-file text-center  ad-card-hover",
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-file-input", {
                                                          attrs: {
                                                            "hide-input": "",
                                                            solo: "",
                                                            accept:
                                                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.tKeysChange,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.targetingKeywordsFile,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.targetingKeywordsFile = $$v
                                                            },
                                                            expression:
                                                              "targetingKeywordsFile",
                                                          },
                                                        }),
                                                        _c("p", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass: " ",
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-upload"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createCampaign.upload"
                                                                  )
                                                                )
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "createCampaign.suppFormat"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addTargetKeywords()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v(" mdi-plus")]
                                                ),
                                                _vm._v(" Keyword Manually "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "6",
                                      lg: "6",
                                      md: "6",
                                      sm: "6",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "ad-groupKeywordStep",
                                        attrs: { elevation: "0" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "ad-group" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "primarybase--text body--text-1 mb-8 mt-6",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.adgroupNegKey"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-left body-2",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "data:text/csv;base64,bmFtZSxtYXRjaAprZXl3b3JkLEVYQUNUCmtleXdvcmQxLEJST0FECg==",
                                                      download:
                                                        "negative-keyword-example.csv",
                                                    },
                                                  },
                                                  [_vm._v("Example Sheet")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primarydark",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "upload-file text-center ad-card-hover",
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-file-input", {
                                                              attrs: {
                                                                "hide-input":
                                                                  "",
                                                                solo: "",
                                                                accept:
                                                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.nKeysChange,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.negativeKeywordsFile,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.negativeKeywordsFile = $$v
                                                                },
                                                                expression:
                                                                  "negativeKeywordsFile",
                                                              },
                                                            }),
                                                            _c("p", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    " ",
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-upload"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createCampaign.upload"
                                                                      )
                                                                    )
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.suppFormat"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addNegativeKeywords()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" mdi-plus ")]
                                                    ),
                                                    _vm._v(
                                                      " Keyword Manually "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _vm.tKeys.length > 0
                                      ? _c(
                                          "v-card",
                                          { attrs: { elevation: "0" } },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "align-center" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      lg: "12",
                                                      md: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          " upload-file mt-0 text-center",
                                                      },
                                                      [
                                                        _c("h5", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createCampaign.targKey"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "elevation-0",
                                                          attrs: {
                                                            headers:
                                                              _vm.tKeysheaders,
                                                            items: _vm.tKeys,
                                                            "items-per-page": 10,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "item.delete",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var item =
                                                                    ref.item
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "32",
                                                                          depressed:
                                                                            "",
                                                                          "x-small":
                                                                            "",
                                                                          fab:
                                                                            "",
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteTkeyFromTable(
                                                                              item
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                "",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete-outline"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3447732025
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clearTKeys()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createCampaign.clear"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _vm.nKeys.length > 0
                                      ? _c(
                                          "v-card",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "align-center" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      lg: "12",
                                                      md: "12",
                                                      sm: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upload-file mt-0 text-center",
                                                      },
                                                      [
                                                        _c("h5", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createCampaign.negKey"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "elevation-0",
                                                          attrs: {
                                                            headers:
                                                              _vm.nKeysheaders,
                                                            items: _vm.nKeys,
                                                            "items-per-page": 10,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "item.delete",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var item =
                                                                    ref.item
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "32",
                                                                          depressed:
                                                                            "",
                                                                          "x-small":
                                                                            "",
                                                                          fab:
                                                                            "",
                                                                          text:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteNkeyFromTable(
                                                                              item
                                                                            )
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              small:
                                                                                "",
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-delete-outline"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2090745699
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.clearNKeys()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "createCampaign.clear"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-2  text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: { color: "disable", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 4
                                      },
                                    },
                                  },
                                  [_vm._v(" Back ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      outlined: "",
                                      large: "",
                                      disabled: _vm.savingCampaignDraft,
                                      loading: _vm.savingCampaignDraft,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAsDraft()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.saveDraft")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      large: "",
                                      disabled: !_vm.tKeys.length,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveKeywords()
                                      },
                                    },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-center" }, [
                              _c("span", { staticClass: " primary--text" }, [
                                _vm._v("Step   "),
                                _c("span", {}, [_vm._v("5")]),
                                _vm._v("/7 "),
                              ]),
                            ]),
                            _c("AddKeys", {
                              attrs: { addKeysData: _vm.addKeywordsData },
                              on: {
                                addKeysCancelClicked:
                                  _vm.addTargetKeysCancelClicked,
                                addKeysSaveClicked: function ($event) {
                                  return _vm.addTargetKeysSaveClicked($event)
                                },
                              },
                            }),
                            _c("AddnKeys", {
                              attrs: { AddnKeysData: _vm.addnKeywordsData },
                              on: {
                                emitAddnKeysCancelClicked:
                                  _vm.addnKeysCancelClicked,
                                emitAddnKeysOkClicked: function ($event) {
                                  return _vm.addnKeysSaveClicked($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "6" } },
                          [
                            _c(
                              "div",
                              { staticClass: "box-header chart-header-area" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "primarybase--text body--text-1 mb-9",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("createCampaign.audPara"))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "mb-3",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "formStep4",
                                    model: {
                                      value: _vm.valid,
                                      callback: function ($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pb-0",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.custType"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.customerTypes,
                                                    attach: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedCustomerType,
                                                    callback: function ($$v) {
                                                      _vm.selectedCustomerType = $$v
                                                    },
                                                    expression:
                                                      "selectedCustomerType",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field d-flex mt-2",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "black--text mb-1 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.demo"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _c("v-switch", {
                                                  staticClass: "mt-0 ml-3 pt-0",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "createCampaign.ageRange"
                                                    ),
                                                  },
                                                  model: {
                                                    value: _vm.ageRange,
                                                    callback: function ($$v) {
                                                      _vm.ageRange = $$v
                                                    },
                                                    expression: "ageRange",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "range mt-3 px-4",
                                              },
                                              [
                                                _c("v-range-slider", {
                                                  attrs: {
                                                    "thumb-label": "always",
                                                    disabled: !_vm.ageRange,
                                                    step: "1",
                                                    min: "18",
                                                    max: "65",
                                                    "background-color": "light",
                                                    "track-color": "grey",
                                                    "track-fill-color":
                                                      "primary",
                                                    "thumb-color": "primary",
                                                  },
                                                  model: {
                                                    value: _vm.ageRangeValue,
                                                    callback: function ($$v) {
                                                      _vm.ageRangeValue = $$v
                                                    },
                                                    expression: "ageRangeValue",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pt-0",
                                            attrs: {
                                              lg: "12",
                                              md: "12",
                                              sm: "12",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.gender"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.gender,
                                                    dense: "",
                                                    attach: "",
                                                  },
                                                  model: {
                                                    value: _vm.selectedGender,
                                                    callback: function ($$v) {
                                                      _vm.selectedGender = $$v
                                                    },
                                                    expression:
                                                      "selectedGender",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field pt-4 select-disable",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: " mb-0 d-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.location"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    "append-icon": "",
                                                    items: _vm.locations,
                                                    disabled:
                                                      _vm.areMultipleCountries,
                                                    messages: _vm.areMultipleCountries
                                                      ? "Not available for campaign targeted for multiple countries"
                                                      : "",
                                                    "item-text": "displayName",
                                                    "return-object": "",
                                                    placeholder: _vm.$t(
                                                      "createCampaign.location"
                                                    ),
                                                    required: "",
                                                    dense: "",
                                                    multiple: "",
                                                    attach: "",
                                                    "hide-no-data": "",
                                                    loading: _vm.loading,
                                                    "search-input":
                                                      _vm.searchLoc,
                                                    "small-chips": "",
                                                    "deletable-chips": "",
                                                  },
                                                  on: {
                                                    "update:searchInput": function (
                                                      $event
                                                    ) {
                                                      _vm.searchLoc = $event
                                                    },
                                                    "update:search-input": function (
                                                      $event
                                                    ) {
                                                      _vm.searchLoc = $event
                                                    },
                                                    change: function ($event) {
                                                      _vm.searchLoc = ""
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.selectedLocation,
                                                    callback: function ($$v) {
                                                      _vm.selectedLocation = $$v
                                                    },
                                                    expression:
                                                      "selectedLocation",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "my-2 text-right" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: { color: "disable", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.step = 5
                                      },
                                    },
                                  },
                                  [_vm._v(" Back ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-3 w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      outlined: "",
                                      large: "",
                                      disabled: _vm.savingCampaignDraft,
                                      loading: _vm.savingCampaignDraft,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveAsDraft()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("createCampaign.saveDraft")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "w-xsm-100 mb-2 px-6",
                                    attrs: {
                                      color: "primary",
                                      depressed: "",
                                      large: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.appendAudience()
                                      },
                                    },
                                  },
                                  [_vm._v(" Continue ")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-center" }, [
                              _c("span", { staticClass: " primary--text" }, [
                                _vm._v("Step   "),
                                _c("span", {}, [_vm._v("6")]),
                                _vm._v("/7 "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { staticClass: "pt-4 pb-0", attrs: { step: "7" } },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "mb-3",
                                attrs: { elevation: "0" },
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "align-center" },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "box-header chart-header-area",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "primarybase--text body--text-1 mb-9",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createCampaign.crSetPara"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "ad-creative mt-8" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "black--text mb-3 d-flex",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "createCampaign.adsOpt"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "my-6" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass: "w-100",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center w-100",
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-6 ml-4 cursor-pointer ad-card d-flex flex-column justify-center align-center",
                                                                  class:
                                                                    _vm.defaultBorder,
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    ripple: false,
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.isCppOption(
                                                                        false
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createCampaign.default"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-center",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createCampaign.defaultAdPara"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-6  cursor-pointer ml-5 ad-card d-flex flex-column justify-center align-center",
                                                                  class:
                                                                    _vm.customBorder,
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    ripple: false,
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.isCppOption(
                                                                        true
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createCampaign.custom"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-center",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createCampaign.customPara"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isCppOptionBool
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "mx-auto pa-0",
                                                          attrs: { cols: "" },
                                                        },
                                                        [
                                                          _vm.selectedCpp == ""
                                                            ? _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "mt-5 d-flex align-center justify-center flex-column py-6 default-card",
                                                                  attrs: {
                                                                    elevation:
                                                                      "0",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.cpp
                                                                    .length &&
                                                                  _vm.selectedCpp ==
                                                                    ""
                                                                    ? _c(
                                                                        "v-card-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .cpp
                                                                                  .length
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createCampaign.pagesInfo"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-card-title",
                                                                        {
                                                                          staticClass:
                                                                            "body--text-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createCampaign.noCrSet"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                  _vm.cpp
                                                                    .length &&
                                                                  _vm.selectedCpp ==
                                                                    ""
                                                                    ? _c(
                                                                        "v-card-subtitle",
                                                                        {
                                                                          staticClass:
                                                                            "text-center mx-4",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createCampaign.createAdPara"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _vm.cpp
                                                                        .length &&
                                                                      _vm.selectedCpp ==
                                                                        ""
                                                                        ? [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-dialog",
                                                                                  {
                                                                                    attrs: {
                                                                                      width:
                                                                                        "642",
                                                                                      persistent:
                                                                                        "",
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "activator",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var on =
                                                                                              ref.on
                                                                                            var attrs =
                                                                                              ref.attrs
                                                                                            return [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                _vm._g(
                                                                                                  _vm._b(
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "w-xsm-100 mb-2",
                                                                                                      attrs: {
                                                                                                        depressed:
                                                                                                          "",
                                                                                                        large:
                                                                                                          "",
                                                                                                        width:
                                                                                                          "120",
                                                                                                        color:
                                                                                                          "primary",
                                                                                                      },
                                                                                                    },
                                                                                                    "v-btn",
                                                                                                    attrs,
                                                                                                    false
                                                                                                  ),
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "createCampaign.createAdBtn"
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      false,
                                                                                      2284491920
                                                                                    ),
                                                                                    model: {
                                                                                      value:
                                                                                        _vm.adDialog,
                                                                                      callback: function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.adDialog = $$v
                                                                                      },
                                                                                      expression:
                                                                                        "adDialog",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-card",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-modal-fixed  create-ads ",
                                                                                        attrs: {
                                                                                          elevation:
                                                                                            "3",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "sub-heading-with-btn mb-0  d-flex align-start justify-space-between sticky-top",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h5",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " primarybase--text h5--text-bold mb-3 ",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.createAds"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "d-flex justify-end primarybase--text ",
                                                                                                on: {
                                                                                                  click: function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.closeAdDialog(
                                                                                                      "close",
                                                                                                      "new"
                                                                                                    )
                                                                                                  },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-close"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "v-card",
                                                                                          {
                                                                                            staticClass:
                                                                                              "py-3 rounded-lg ",
                                                                                            attrs: {
                                                                                              elevation:
                                                                                                "0",
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "p",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "primarybase--text body--text-1 mb-0",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.selectCustomPara"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mt-0 d-flex align-start",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      " ",
                                                                                                    attrs: {
                                                                                                      cols:
                                                                                                        "12\n                                                  ",
                                                                                                      lg: _vm.tempSelectedCpp
                                                                                                        ? 12
                                                                                                        : 12,
                                                                                                      md: _vm.tempSelectedCpp
                                                                                                        ? 12
                                                                                                        : 12,
                                                                                                      sm: _vm.tempSelectedCpp
                                                                                                        ? 12
                                                                                                        : 12,
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-flex align-center",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              " pt-0 d-flex justify-end",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                              lg:
                                                                                                                "12",
                                                                                                              sm:
                                                                                                                "12",
                                                                                                              md:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "max-200 ",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      width:
                                                                                                                        "100%",
                                                                                                                      "append-icon":
                                                                                                                        "mdi-magnify",
                                                                                                                      label: _vm.$t(
                                                                                                                        "createCampaign.search"
                                                                                                                      ),
                                                                                                                      "hide-details":
                                                                                                                        "",
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        _vm.cppSearch,
                                                                                                                      callback: function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.cppSearch = $$v
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "cppSearch",
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "pb-0",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                              lg:
                                                                                                                "9",
                                                                                                              sm:
                                                                                                                "9",
                                                                                                              md:
                                                                                                                "9",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "field",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "label",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      " mb-3 d-flex",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "createCampaign.adName"
                                                                                                                        )
                                                                                                                      ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      required:
                                                                                                                        "",
                                                                                                                      outlined:
                                                                                                                        "",
                                                                                                                      dense:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        _vm.adName,
                                                                                                                      callback: function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.adName = $$v
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "adName",
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "pb-0",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                              lg:
                                                                                                                "3",
                                                                                                              sm:
                                                                                                                "3",
                                                                                                              md:
                                                                                                                "3",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "field",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "label",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mb-3 d-flex",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "Sort By"
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-select",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      items:
                                                                                                                        _vm.cppSortBy,
                                                                                                                      outlined:
                                                                                                                        "",
                                                                                                                      dense:
                                                                                                                        "",
                                                                                                                      attach:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        _vm.sortBy,
                                                                                                                      callback: function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.sortBy = $$v
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "sortBy",
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "page-count text-body-2 primarybase--text ",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "createCampaign.available"
                                                                                                              )
                                                                                                            ) +
                                                                                                            " : " +
                                                                                                            _vm._s(
                                                                                                              _vm
                                                                                                                .cpp
                                                                                                                .length
                                                                                                            ) +
                                                                                                            " "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          " mt-0 d-flex align-start",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "d-flex align-center justify-space-between flex-wrap pt-0",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          _vm._l(
                                                                                                            _vm.filteredCppList,
                                                                                                            function (
                                                                                                              item
                                                                                                            ) {
                                                                                                              return _c(
                                                                                                                "v-card",
                                                                                                                {
                                                                                                                  key:
                                                                                                                    item.id,
                                                                                                                  staticClass:
                                                                                                                    "  ad-template   py-4 cursor-pointer d-flex flex-column align-center ",
                                                                                                                  class: _vm.selectCpp(
                                                                                                                    item.id
                                                                                                                  ),
                                                                                                                  attrs: {
                                                                                                                    elevation:
                                                                                                                      "0",
                                                                                                                  },
                                                                                                                  on: {
                                                                                                                    click: function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.setCppOptions(
                                                                                                                        item
                                                                                                                      )
                                                                                                                    },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-img",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "ma-0",
                                                                                                                      attrs: {
                                                                                                                        src:
                                                                                                                          item.screenshots,
                                                                                                                        height:
                                                                                                                          "auto",
                                                                                                                        width:
                                                                                                                          "80px",
                                                                                                                      },
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "h6",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          item.name
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "label",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " " +
                                                                                                                          _vm._s(
                                                                                                                            item
                                                                                                                              .locale[0]
                                                                                                                              .promotionalText
                                                                                                                          ) +
                                                                                                                          " "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              )
                                                                                                            }
                                                                                                          ),
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _vm.tempSelectedCpp
                                                                                                  ? _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "12",
                                                                                                          lg:
                                                                                                            "12",
                                                                                                          md:
                                                                                                            "12",
                                                                                                          sm:
                                                                                                            "12",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-card",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "cust-pro-image mx-auto  max-275",
                                                                                                            attrs: {
                                                                                                              elevation:
                                                                                                                "1",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-carousel",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  "hide-delimiter-background":
                                                                                                                    "",
                                                                                                                  "delimiter-icon":
                                                                                                                    "mdi-minus",
                                                                                                                  height:
                                                                                                                    "auto",
                                                                                                                },
                                                                                                              },
                                                                                                              _vm._l(
                                                                                                                _vm
                                                                                                                  .selectedDeviceScreenSorts
                                                                                                                  .screenshots,
                                                                                                                function (
                                                                                                                  item
                                                                                                                ) {
                                                                                                                  return _c(
                                                                                                                    "v-carousel-item",
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        _vm.i,
                                                                                                                      attrs: {
                                                                                                                        src:
                                                                                                                          item.assetUrl,
                                                                                                                        cover:
                                                                                                                          "",
                                                                                                                      },
                                                                                                                    }
                                                                                                                  )
                                                                                                                }
                                                                                                              ),
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "select-cus w-100 px-4",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "select",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "model",
                                                                                                                        rawName:
                                                                                                                          "v-model",
                                                                                                                        value:
                                                                                                                          _vm.selectedStatusChange,
                                                                                                                        expression:
                                                                                                                          "selectedStatusChange",
                                                                                                                      },
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      name:
                                                                                                                        "format",
                                                                                                                      id:
                                                                                                                        "format",
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      change: [
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                                                            .call(
                                                                                                                              $event
                                                                                                                                .target
                                                                                                                                .options,
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                return o.selected
                                                                                                                              }
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                var val =
                                                                                                                                  "_value" in
                                                                                                                                  o
                                                                                                                                    ? o._value
                                                                                                                                    : o.value
                                                                                                                                return val
                                                                                                                              }
                                                                                                                            )
                                                                                                                          _vm.selectedStatusChange = $event
                                                                                                                            .target
                                                                                                                            .multiple
                                                                                                                            ? $$selectedVal
                                                                                                                            : $$selectedVal[0]
                                                                                                                        },
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.changeLang(
                                                                                                                            $event,
                                                                                                                            $event
                                                                                                                              .target
                                                                                                                              .selectedIndex
                                                                                                                          )
                                                                                                                        },
                                                                                                                      ],
                                                                                                                    },
                                                                                                                  },
                                                                                                                  _vm._l(
                                                                                                                    _vm.selectDeviceLang,
                                                                                                                    function (
                                                                                                                      item,
                                                                                                                      i
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        "option",
                                                                                                                        {
                                                                                                                          domProps: {
                                                                                                                            value: i,
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            " " +
                                                                                                                              _vm._s(
                                                                                                                                item
                                                                                                                              )
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  0
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "select-cus w-100 px-4",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "select",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "model",
                                                                                                                        rawName:
                                                                                                                          "v-model",
                                                                                                                        value:
                                                                                                                          _vm.selectedStatus,
                                                                                                                        expression:
                                                                                                                          "selectedStatus",
                                                                                                                      },
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      name:
                                                                                                                        "format",
                                                                                                                      id:
                                                                                                                        "format",
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      change: [
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                                                            .call(
                                                                                                                              $event
                                                                                                                                .target
                                                                                                                                .options,
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                return o.selected
                                                                                                                              }
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                var val =
                                                                                                                                  "_value" in
                                                                                                                                  o
                                                                                                                                    ? o._value
                                                                                                                                    : o.value
                                                                                                                                return val
                                                                                                                              }
                                                                                                                            )
                                                                                                                          _vm.selectedStatus = $event
                                                                                                                            .target
                                                                                                                            .multiple
                                                                                                                            ? $$selectedVal
                                                                                                                            : $$selectedVal[0]
                                                                                                                        },
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.changeDevice(
                                                                                                                            $event,
                                                                                                                            $event
                                                                                                                              .target
                                                                                                                              .selectedIndex
                                                                                                                          )
                                                                                                                        },
                                                                                                                      ],
                                                                                                                    },
                                                                                                                  },
                                                                                                                  _vm._l(
                                                                                                                    _vm.selectDeviceName,
                                                                                                                    function (
                                                                                                                      item,
                                                                                                                      i
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        "option",
                                                                                                                        {
                                                                                                                          domProps: {
                                                                                                                            value: i,
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            " " +
                                                                                                                              _vm._s(
                                                                                                                                item
                                                                                                                              )
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  0
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex align-end justify-end ",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "w-xsm-100 px-6 mb-2",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  depressed:
                                                                                                    "",
                                                                                                  disabled:
                                                                                                    _vm.adName ==
                                                                                                      "" ||
                                                                                                    _vm.tempSelectedCpp ==
                                                                                                      "",
                                                                                                  large:
                                                                                                    "",
                                                                                                },
                                                                                                on: {
                                                                                                  click: function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.closeAdDialog(
                                                                                                      "save",
                                                                                                      "new"
                                                                                                    )
                                                                                                  },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createCampaign.save"
                                                                                                      )
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        : _vm._e(),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.selectedCpp
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "h5",
                                                                    {
                                                                      staticClass:
                                                                        "ma-4",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createCampaign.customAds"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "pa-6 mx-4 my-4 br d-flex align-start ad-card ",
                                                                      attrs: {
                                                                        elevation:
                                                                          "0",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex flex-column align-center justify-center margin-auto ",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "cust-ads-img ",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-img",
                                                                                {
                                                                                  attrs: {
                                                                                    src:
                                                                                      _vm.selectedCppScreenshot,
                                                                                    alt:
                                                                                      "",
                                                                                    "max-width":
                                                                                      "100%",
                                                                                    height:
                                                                                      "100%",
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "h5",
                                                                            {
                                                                              staticClass:
                                                                                "mt-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.adName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "text-center mb-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.selectedCppName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.selectedCpp
                                                                            ? [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-center",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-dialog",
                                                                                      {
                                                                                        attrs: {
                                                                                          width:
                                                                                            "642",
                                                                                          persistent:
                                                                                            "",
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function (
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                var attrs =
                                                                                                  ref.attrs
                                                                                                return [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "primary--text",
                                                                                                          attrs: {
                                                                                                            text:
                                                                                                              "",
                                                                                                            small:
                                                                                                              "",
                                                                                                          },
                                                                                                        },
                                                                                                        "v-btn",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "createCampaign.edit"
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          false,
                                                                                          3978590984
                                                                                        ),
                                                                                        model: {
                                                                                          value:
                                                                                            _vm.adDialogEdit,
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.adDialogEdit = $$v
                                                                                          },
                                                                                          expression:
                                                                                            "adDialogEdit",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-card",
                                                                                          {
                                                                                            staticClass:
                                                                                              "v-modal-fixed create-ads",
                                                                                            attrs: {
                                                                                              elevation:
                                                                                                "3",
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "sub-heading-with-btn mb-0  d-flex align-start justify-space-between sticky-top",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "h5",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      " primarybase--text h5--text-bold mb-3 ",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createCampaign.createAds"
                                                                                                          )
                                                                                                        ) +
                                                                                                        " "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "d-flex justify-end primarybase--text ",
                                                                                                    on: {
                                                                                                      click: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.closeAdDialog(
                                                                                                          "close",
                                                                                                          "edit"
                                                                                                        )
                                                                                                      },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-close"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {},
                                                                                              [
                                                                                                _c(
                                                                                                  "v-card",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "py-3 rounded-lg ",
                                                                                                    attrs: {
                                                                                                      elevation:
                                                                                                        "0",
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "p",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "primarybase--text body--text-1 mb-0",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "createCampaign.selectCustomPara"
                                                                                                              )
                                                                                                            ) +
                                                                                                            " "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          " mt-0 d-flex align-start",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "pb-0",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                              lg:
                                                                                                                "9",
                                                                                                              sm:
                                                                                                                "9",
                                                                                                              md:
                                                                                                                "9",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "field",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "label",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      " mb-3 d-flex",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "createCampaign.adName"
                                                                                                                        )
                                                                                                                      ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-text-field",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      required:
                                                                                                                        "",
                                                                                                                      outlined:
                                                                                                                        "",
                                                                                                                      dense:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        _vm.adName,
                                                                                                                      callback: function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.adName = $$v
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "adName",
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "pb-0",
                                                                                                            attrs: {
                                                                                                              col:
                                                                                                                "12",
                                                                                                              lg:
                                                                                                                "3",
                                                                                                              sm:
                                                                                                                "3",
                                                                                                              md:
                                                                                                                "3",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "field",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "label",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "mb-3 d-flex",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "Sort By"
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-select",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      items:
                                                                                                                        _vm.cppSortBy,
                                                                                                                      outlined:
                                                                                                                        "",
                                                                                                                      dense:
                                                                                                                        "",
                                                                                                                      attach:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        _vm.sortBy,
                                                                                                                      callback: function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.sortBy = $$v
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "sortBy",
                                                                                                                    },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "page-count text-body-2 primarybase--text ",
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "createCampaign.available"
                                                                                                              )
                                                                                                            ) +
                                                                                                            " : " +
                                                                                                            _vm._s(
                                                                                                              _vm
                                                                                                                .cpp
                                                                                                                .length
                                                                                                            ) +
                                                                                                            " "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          " mt-0 d-flex align-start",
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "d-flex align-center justify-space-between flex-wrap pt-0",
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          _vm._l(
                                                                                                            _vm.filteredCppList,
                                                                                                            function (
                                                                                                              item
                                                                                                            ) {
                                                                                                              return _c(
                                                                                                                "v-card",
                                                                                                                {
                                                                                                                  key:
                                                                                                                    item.id,
                                                                                                                  staticClass:
                                                                                                                    "  ad-template   py-4 cursor-pointer d-flex flex-column align-center ",
                                                                                                                  class: _vm.selectCpp(
                                                                                                                    item.id
                                                                                                                  ),
                                                                                                                  attrs: {
                                                                                                                    elevation:
                                                                                                                      "0",
                                                                                                                  },
                                                                                                                  on: {
                                                                                                                    click: function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.setCppOptions(
                                                                                                                        item
                                                                                                                      )
                                                                                                                    },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-img",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "ma-0",
                                                                                                                      attrs: {
                                                                                                                        src:
                                                                                                                          item.screenshots,
                                                                                                                        height:
                                                                                                                          "auto",
                                                                                                                        width:
                                                                                                                          "80px",
                                                                                                                      },
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "h6",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " " +
                                                                                                                          _vm._s(
                                                                                                                            item.name
                                                                                                                          ) +
                                                                                                                          " "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "label",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        " " +
                                                                                                                          _vm._s(
                                                                                                                            item
                                                                                                                              .locale[0]
                                                                                                                              .promotionalText
                                                                                                                          ) +
                                                                                                                          " "
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ],
                                                                                                                1
                                                                                                              )
                                                                                                            }
                                                                                                          ),
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "12",
                                                                                                          lg:
                                                                                                            "12",
                                                                                                          md:
                                                                                                            "12",
                                                                                                          sm:
                                                                                                            "12",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-card",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "cust-pro-image mx-auto  max-275",
                                                                                                            attrs: {
                                                                                                              elevation:
                                                                                                                "1",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-carousel",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  "hide-delimiter-background":
                                                                                                                    "",
                                                                                                                  "delimiter-icon":
                                                                                                                    "mdi-minus",
                                                                                                                  height:
                                                                                                                    "auto",
                                                                                                                },
                                                                                                              },
                                                                                                              _vm._l(
                                                                                                                _vm
                                                                                                                  .selectedDeviceScreenSorts
                                                                                                                  .screenshots,
                                                                                                                function (
                                                                                                                  item
                                                                                                                ) {
                                                                                                                  return _c(
                                                                                                                    "v-carousel-item",
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        _vm.i,
                                                                                                                      attrs: {
                                                                                                                        src:
                                                                                                                          item.assetUrl,
                                                                                                                        cover:
                                                                                                                          "",
                                                                                                                      },
                                                                                                                    }
                                                                                                                  )
                                                                                                                }
                                                                                                              ),
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "select-cus w-100 px-4",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "select",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "model",
                                                                                                                        rawName:
                                                                                                                          "v-model",
                                                                                                                        value:
                                                                                                                          _vm.selectedStatusChange,
                                                                                                                        expression:
                                                                                                                          "selectedStatusChange",
                                                                                                                      },
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      name:
                                                                                                                        "format",
                                                                                                                      id:
                                                                                                                        "format",
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      change: [
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                                                            .call(
                                                                                                                              $event
                                                                                                                                .target
                                                                                                                                .options,
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                return o.selected
                                                                                                                              }
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                var val =
                                                                                                                                  "_value" in
                                                                                                                                  o
                                                                                                                                    ? o._value
                                                                                                                                    : o.value
                                                                                                                                return val
                                                                                                                              }
                                                                                                                            )
                                                                                                                          _vm.selectedStatusChange = $event
                                                                                                                            .target
                                                                                                                            .multiple
                                                                                                                            ? $$selectedVal
                                                                                                                            : $$selectedVal[0]
                                                                                                                        },
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.changeLang(
                                                                                                                            $event,
                                                                                                                            $event
                                                                                                                              .target
                                                                                                                              .selectedIndex
                                                                                                                          )
                                                                                                                        },
                                                                                                                      ],
                                                                                                                    },
                                                                                                                  },
                                                                                                                  _vm._l(
                                                                                                                    _vm.selectDeviceLang,
                                                                                                                    function (
                                                                                                                      item,
                                                                                                                      i
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        "option",
                                                                                                                        {
                                                                                                                          domProps: {
                                                                                                                            value: i,
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            " " +
                                                                                                                              _vm._s(
                                                                                                                                item
                                                                                                                              )
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  0
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "select-cus w-100 px-4",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "select",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "model",
                                                                                                                        rawName:
                                                                                                                          "v-model",
                                                                                                                        value:
                                                                                                                          _vm.selectedStatus,
                                                                                                                        expression:
                                                                                                                          "selectedStatus",
                                                                                                                      },
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      name:
                                                                                                                        "format",
                                                                                                                      id:
                                                                                                                        "format",
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      change: [
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                                                            .call(
                                                                                                                              $event
                                                                                                                                .target
                                                                                                                                .options,
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                return o.selected
                                                                                                                              }
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                              function (
                                                                                                                                o
                                                                                                                              ) {
                                                                                                                                var val =
                                                                                                                                  "_value" in
                                                                                                                                  o
                                                                                                                                    ? o._value
                                                                                                                                    : o.value
                                                                                                                                return val
                                                                                                                              }
                                                                                                                            )
                                                                                                                          _vm.selectedStatus = $event
                                                                                                                            .target
                                                                                                                            .multiple
                                                                                                                            ? $$selectedVal
                                                                                                                            : $$selectedVal[0]
                                                                                                                        },
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.changeDevice(
                                                                                                                            $event,
                                                                                                                            $event
                                                                                                                              .target
                                                                                                                              .selectedIndex
                                                                                                                          )
                                                                                                                        },
                                                                                                                      ],
                                                                                                                    },
                                                                                                                  },
                                                                                                                  _vm._l(
                                                                                                                    _vm.selectDeviceName,
                                                                                                                    function (
                                                                                                                      item,
                                                                                                                      i
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        "option",
                                                                                                                        {
                                                                                                                          domProps: {
                                                                                                                            value: i,
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            " " +
                                                                                                                              _vm._s(
                                                                                                                                item
                                                                                                                              )
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  0
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      " d-flex align-end justify-end  py-3  ",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "w-xsm-100 mb-2 px-6",
                                                                                                        attrs: {
                                                                                                          width:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          depressed:
                                                                                                            "",
                                                                                                          disabled:
                                                                                                            _vm.adName ==
                                                                                                              "" ||
                                                                                                            _vm.tempSelectedCpp ==
                                                                                                              "",
                                                                                                          large:
                                                                                                            "",
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.closeAdDialog(
                                                                                                              "save",
                                                                                                              "edit"
                                                                                                            )
                                                                                                          },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          " " +
                                                                                                            _vm._s(
                                                                                                              _vm.$t(
                                                                                                                "createCampaign.save"
                                                                                                              )
                                                                                                            ) +
                                                                                                            " "
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm.isCppOptionBool
                                        ? _c(
                                            "label",
                                            {
                                              staticClass:
                                                "my-2 secondary--text",
                                            },
                                            [_vm._v(_vm._s(_vm.cppError))]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-dialog",
                              {
                                attrs: { persistent: "", "max-width": "600px" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-column" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "my-2 text-right",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mr-4 w-xsm-100  px-6",
                                                    attrs: {
                                                      color: "disable",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.step = 6
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Back ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      " px-6 mr-4 v-btn v-btn--has-bg theme--light v-size--default primary white--text",
                                                    attrs: {
                                                      text: "",
                                                      outlined: "",
                                                      large: "",
                                                      loading:
                                                        _vm.savingCampaignDraft,
                                                      disabled:
                                                        _vm.savingCampaignDraft,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveAsDraft()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.saveDraft"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "w-xsm-100  px-6",
                                                      attrs: {
                                                        depressed: "",
                                                        large: "",
                                                        color: "primary",
                                                        loading:
                                                          _vm.savingCampaign,
                                                        disabled:
                                                          _vm.savingCampaign ||
                                                          !_vm.valid1 ||
                                                          !_vm.valid2 ||
                                                          !_vm.valid3,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.createCampaign,
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createCampaign.createCamp"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      " primary--text",
                                                  },
                                                  [
                                                    _vm._v("Step   "),
                                                    _c("span", {}, [
                                                      _vm._v("7"),
                                                    ]),
                                                    _vm._v("/7 "),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.successDialog,
                                  callback: function ($$v) {
                                    _vm.successDialog = $$v
                                  },
                                  expression: "successDialog",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-5" },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticClass:
                                          "d-flex justify-end pa-0 mb-10",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeSuccessDialog()
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-close")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "text-center py-10" },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "pb-4 font-weight-medium black--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createCampaign.campCreated"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createCampaign.campCreatedPara"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "mt-6",
                                                attrs: { justify: "center" },
                                              },
                                              [
                                                _c("v-img", {
                                                  attrs: {
                                                    "max-height": "173",
                                                    "max-width": "173",
                                                    src: require("../../assets/images/success-check.svg"),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "justify-end mt-7 pa-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "127",
                                              color: "primary",
                                              depressed: "",
                                              large: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeSuccessDialog()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("createCampaign.close")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }