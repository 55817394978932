










































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { COUNTRIES } from '../../utils/constants';
import { LSS } from '../../core/services';
import { CampaignsService } from '@services/campaigns.service';
import { CustomerTypes, Genders } from '../../utils/enums';
import {
  IAudience,
  ICampaign,
  IAdGroup,
  ICampaignReq,
} from '@shared/interfaces';
import XLSX from 'xlsx';

import AddCreativeSet from '../campaign/AddCreativeSet.vue';

import { LS_DRAFT_KEY } from '@shared/config';
import {
  GET_CAMPAIGN_DRAFT,
  GET_CAMPAIGN_GROUPS,
  APPS_BY_ORG,
  COUNTRIES_FOR_APP,
} from '../../utils/apollo-queries';
import {
  CAMPAIGN_DRAFT,
  CREATE_CAMPAIGN,
  CREATE_SEARCH_TAB_CAMPAIGN,
} from '../../utils/apollo-mutations';
import { CampaignCreationSteps } from '../../utils/enums';
import { IMutationOptions } from '../../utils/interfaces';
import AddKeys from '../keywords/AddKeys.vue';
import AddnKeys from '../keywords/AddnKeys.vue';
import { forEach } from 'lodash';
import { KOCHAVA_BUDGET, KOCHAVA_COMPANY } from '@config';
import { Base64 } from 'js-base64';

export default Vue.extend({
   name: 'SearchTabDialog',
  props: {
    searchTabMetadata: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddCreativeSet,
    AddKeys,
    AddnKeys,
  },
  data() {
    return {
      searchInput:null,
      search: null,
      timerId: null,
      addedCreativeSetDetails: null,
      expandAdScheduling: false,
      savingCampaign: false,
      csCountries: [],
      menuDisplays: null,
      ex4: null,
      successDialog: false,
      phones: [
        {
          size: '6.5” Display',
          description: 'Display iPhone XS Max',
        },
        {
          size: '6.1” Display',
          description: 'Display iPhone XR',
        },
        {
          size: '5.8” Display',
          description: 'Display iPhone X, iPhone XS',
        },
      ],
      ipads: [
        {
          size: '12.9” Display',
          description: 'Display iPad Pro 12.9”',
        },
        {
          size: '9.7” Display',
          description:
            'Display iPad Pro 9.7”, iPad (2017), iPad Air 2, iPad Air, iPad (4th generation)',
        },
      ],
      nKeysheaders: [
        {
          text: '',
          align: 'start',
          value: 'delete',
        },
        {
          text: 'Keyword',
          sortable: true,
          value: 'text',
        },
        { text: 'matchType', value: 'matchType' },
      ],
      tKeysheaders: [
        {
          text: '',
          align: 'start',
          value: 'delete',
        },
        {
          text: 'Keyword',
          sortable: true,
          value: 'text',
        },
        { text: 'MatchType', value: 'matchType' },
        { text: 'Amount', value: 'bidAmount.amount' },
        { text: 'Currrency', value: 'bidAmount.currency' },
      ],
      orgId: '',
      appList: [],
      currency: '',
      loading: false,
      searchedApps: [],
      searchLoc: null,
      selectedApp: null,
      campaginGroups: [],
      drawer: true,
      mini: true,
      step: CampaignCreationSteps.APP_SELECTION,

      switch1: true,
      switch2: false,

      dialog: false,

      e2: 'Texas',
      e3: null,
      e4: null,
      states: [
        { text: 'State 1' },
        { text: 'State 2' },
        { text: 'State 3' },
        { text: 'State 4' },
        { text: 'State 5' },
        { text: 'State 6' },
        { text: 'State 7' },
      ],
      nullDatetime: null,
      datePickerProps: {
        min: new Date().toISOString(),
      },
      timePickerProps: {
        min: `${new Date().getHours()}:${new Date().getMinutes()}`,
      },
      datetime: new Date(),
      datetimeString: '2019-01-01 12:00',
      formattedDatetime: '09/01/2019 12:00',
      textFieldProps: {
        appendIcon: 'event',
      },
      dateProps: {
        headerColor: 'red',
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      endTimeCheck: false,
      //start
      valid: true,

      //campaign
      campaignGroupName: '',
      appId: null,
      appDetailsForCreativeSet: null,
      savingCountriesRegions: false,

      //Countries Regions
      campaignName: null,
      selectedCountries: null,
      appCountries: [],
      Countries: null,
      budget: null,
      dailyCap: '',
      areMultipleCountries: false,

      //adgroup
      adGroupName: '',
      selectDevices: '',
      Devices: [
        { text: 'IPAD', src: 'mdi-tablet' },
        { text: 'IPHONE', src: 'mdi-cellphone' },
      ],
      startdate: null,
      enddate: '',
      menu1: false,
      startmodal: false,
      endmodal: false,
      menu2: false,
      defaultBidAmount: '',
      cpaGoal: '',
      daypart: null,
      checkbox: true,
      menu: null,
      date: null,

      //Keywords
      tKeys: [],
      nKeys: [],

      //search match
      autoOpt: false,

      ageRange: false,
      ageRangeValue: [18, 35],
      locations: [],

      selectedGender: Genders.ALL,
      selectedCustomerType: CustomerTypes.ALL,
      selectedLocation: '',
      isAppSearchLoading: false,

      //creative sets
      creativeappassets: null,
      creativeSetName: [],
      csName: '',
      tab: null,
      allImgs: {},
      selectedImgs: [],
      defaultImages: [],
      defaultGenIds: [],

      //Validation
      valid1: false,
      campaignGroupRules: [(v) => !!v || 'Please select campaign group!'],
      appRules: [(v) => !!v || 'Please select an app!'],

      valid2: false,
      countryRules: [(v) => !!(v && v.length) || 'Please select country!'],
      campaignNameRules: [(v) => !!v || 'Campaign name required!'],
      // budgetRules: [
      //   (v) => !!v || 'Budget amount is required!',
      //   (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid budget!',
      // ],

      valid3: false,
      adgroupNameRules: [(v) => !!v || 'Adgroup name required!'],
      
      // dailyCapRules:[
      //   (v) =>{
      //     if (v!=null) {
      //       return (   !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid daily cap!');
      //     }
      //   }
         
      // ],
      cpaGoalRules: [
        (v) =>
          !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid CPA goal!',
      ],
      deviceRules: [(v) => !!(v && v.length) || 'Please select devices!'],

      clickOutsideEnabled: false,
    };

    
  },
  computed: {
    c: function (): string {
      return '';
    },
    // dailyCapRules: {
    //   get() {
    //     if(this.dailyCap!=null){
    //       return [
    //       (v) =>
    //         !!/^[0.1-9|(^$)]*$/gi.test(v) ||
    //         this.$t('createCampaignRules.validDailyCap'), // this.$t('createCampaignRules.validDailyCap')
    //     ];
    //     }
       
    //   },
    // },
    cptBidRules: {
      get() {
        return [
        (v) => !!v || 'Default Max CPT Bid is required!',
        (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
        (v) => (v <= this.limitAmount)|| "must be less than or equal to " + this.limitAmount,
        (v) => (v >= 0.01 && v < 1000) || "budget must be in between $0.01 and $1,000.00"

      ]
      }
    },
    budgetRules: {
      get() {
        if(!this.dailyCap){
          if(!this.budget){
            return["either budget or daily cap is required"]
          }
        }
        if(this.budget){
          return [
          // (v) => !!v || 'Budget amount is required!',
          (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid budget!',
          (v) => (v >= 0.01 && v < 20000000) || "budget must be in between $0.01 and $20,000,000.00",
          (v) => !((Base64.decode(LSS.company) == KOCHAVA_COMPANY || LSS.company == KOCHAVA_COMPANY) && v > KOCHAVA_BUDGET)||"A maximum campaign budget of $1 is allowed."
        ]}
      }},
      dailyCapRules: {
        cache:false,
      get() {
        if(!this.budget){
          if(!this.dailyCap){
            return["either budget or daily cap is required"]
          }
          else{
            if(this.dailyCap!=null && this.dailyCap!=''){
              return [
              (v) =>
                !!/^[0.1-9|(^$)]*$/gi.test(v) ||
                this.$t('createCampaignRules.validDailyCap'), // this.$t('createCampaignRules.validDailyCap')
                (v) => (v >= 0.01 && v < 20000000) || "budget must be in between $0.01 and $20,000,000.00",
                (v) => !((Base64.decode(LSS.company) == KOCHAVA_COMPANY || LSS.company == KOCHAVA_COMPANY) && v > KOCHAVA_BUDGET)||"A maximum campaign budget of $1 is allowed."

            ];
            }
          }
        }
        else{
          if(this.dailyCap!=null && this.dailyCap!=''){
              return [
              (v) =>
                !!/^[0.1-9|(^$)]*$/gi.test(v) ||
                this.$t('createCampaignRules.validDailyCap'), // this.$t('createCampaignRules.validDailyCap')
                (v) => (v >= 0.01 && v < 20000000) || "budget must be in between $0.01 and $20,000,000.00",
                (v) => (v<= Number(this.budget))|| "daily cap must be less than or equal to budget",
                (v) => !((Base64.decode(LSS.company) == KOCHAVA_COMPANY || LSS.company == KOCHAVA_COMPANY) && v > KOCHAVA_BUDGET)||"A maximum campaign budget of $1 is allowed."

            ];
            }
        }
      },
    },
    limitAmount:{
      get(){
        if (this.dailyCap!=null && this.dailyCap!="") return Number(this.dailyCap)
        return Number(this.budget)
      }
    },
    draftId: {
      cache: false,
      get(): string {
        return localStorage.getItem(LS_DRAFT_KEY) as string;
      },
      set(value: string): void {
        localStorage.setItem(LS_DRAFT_KEY, value);
      },
    },
    //audience
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
  },
  apollo: {
    campaignDraft: {
      query: GET_CAMPAIGN_DRAFT,
      variables() {
        return {
          draftId: this.draftId,
        };
      },
      skip: true,
      update(data) {
        const campaignDraft: ICampaignReq = data.campaignDraft;
        LSS.campaign = campaignDraft.campaign;
        LSS.adGroup = campaignDraft.adGroup;
        LSS.orgId = campaignDraft.campaign.orgId;
        LSS.adamId = campaignDraft.campaign.adamId;
        this.step = campaignDraft.step + 1;
      },
    },
    getCampaignGroups: {
      query: GET_CAMPAIGN_GROUPS,
      skip(): boolean {
        return false;
      },
    },
    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.selectedApp.adamId,
        };
      },
      skip() {
        return !this.selectedApp;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
  },
  methods: {
    onClickOutside() {
      this.startdate = this.$refs.dateTimePickerRef.selectedDatetime
    },

    onCloseConditional(e) {
      return this.clickOutsideEnabled
    },

    resetDialog(){
    this.dialog = false; 
    this.$refs.formA.reset();
    this.$refs.formB.reset();
    this.$refs.formC.reset();
    this.$refs.formStep3.reset();
    this.startdate = null
    this.step = CampaignCreationSteps.APP_SELECTION;
    },
     back(){
     this.$emit('emitBackStep');
     this.dialog=false;
    },
    async searchForApps(query) {
      if(!this.orgId) return

      clearTimeout(this.timerId)

    // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.isAppSearchLoading = true;
        const result = await this.$apollo.query({
          query: APPS_BY_ORG,
          variables: {
            orgId: this.orgId,
            query: query
          }
        });

        await this.appListing(result.data.apps);

        this.isAppSearchLoading = false;
      }, 800)
    },
    appListing(data) {
      this.appList = [];
      for (const app of data) {
        if(LSS.ownApps && LSS.ownApps.length > 0) {
          for (let index = 0; index < LSS.ownApps.length; index++) {
            const element = LSS.ownApps[index];      
            if(element.adamId == app.adamId){
              this.appList.push({
                appName: element.appName,
                adamId: app.adamId,
                appIcon: element.appIcon,
              });
            }
          }
        }
      }
    },

    closeSuccessDialog() {
      this.successDialog = false;
      window.location.reload();
      LSS.clearOther();
    },
    async createCampaign() {
      this.savingCampaign = true;
      const {startTime:startTimeC , ...campaignWithoutStartDate} = LSS.campaign
      const {startTime:startTimeA, ...adgroupWithoutStartDate} = LSS.adGroup
      const campaignRequest: ICampaignReq = {
        campaign: {
          ...campaignWithoutStartDate,
          ...((new Date(this.startdate) > new Date()) && {
          startTime: this.startdate
        })
        },
        adGroup: {
          ...adgroupWithoutStartDate,
          ...((new Date(this.startdate) > new Date()) && {
          startTime: this.startdate
        })
        },
      };
      try {
        const result = await this.runMutation({
          mutationQuery: CREATE_SEARCH_TAB_CAMPAIGN,
          variables: { orgId: LSS.orgId, campaignRequest },
        });
        console.log('createCampaignResponse in SearchTabDailog :',result);
        this.savingCampaign = false;
        if (result.data.createSearchTabCampaign.searchAdsErrors) {
          return this.$notify.error(
            result.data.createSearchTabCampaign.searchAdsErrors
          );
        }
        this.successDialog = true;
      } catch (error) {
        this.savingCampaign = false;
        console.log(error);
      }
    },
    async saveAsDraft() {
      const campaignDraft: ICampaignReq = {
        campaign: LSS.campaign,
        adGroup: LSS.adGroup,
        step: this.step,
      };

      await this.runMutation({
        mutationQuery: CAMPAIGN_DRAFT,
        variables: { campaignDraft },
      });
    },
    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      this.locations = await CampaignsService.searchedLocations(
        this.orgId,
        locationName,
        countrycode
      );
      this.loading = false;
    },
    setProperties() {
      LSS.orgId = this.orgId;
      LSS.adamId = this.selectedApp.adamId;

      this.step = CampaignCreationSteps.COUNTRIES_REGIONS;
    },
    async saveCountriesRegions() {
      this.$refs.cptBidRef.validate()
      const campaign: ICampaign = {
        countriesOrRegions: this.selectedCountries,
        name: this.campaignName,
        ...(this.budget && {
          budgetAmount: {
            currency: this.currency,
            amount: this.budget,
          },
        }),
        ...(this.dailyCap && {
          dailyBudgetAmount: {
            currency: this.currency,
            amount: this.dailyCap,
          },
        }),
        orgId: parseInt(LSS.orgId),
        adChannelType: 'DISPLAY',
        supplySources: ['APPSTORE_SEARCH_TAB'],
        adamId: parseInt(LSS.adamId),

        billingEvent: 'TAPS',
      };

      LSS.campaign = campaign;
      this.step = CampaignCreationSteps.AD_GROUP;
    },

    async saveAdGroupObject() {
      LSS.campaign = {
        ...LSS.campaign,
        startTime: this.startdate,
        ...(this.enddate && {
          endTime: this.enddate,
        }),
      };

      const adGroup: IAdGroup = {
        orgId: parseInt(LSS.orgId),
        status: 'ENABLED',
        name: this.adGroupName,
        pricingModel: 'CPC',
        defaultBidAmount: {
          currency: this.currency,
          amount: this.defaultBidAmount,
        },
        ...(this.cpaGoal && {
          cpaGoal: {
            currency: this.currency,
            amount: this.cpaGoal,
          },
        }),
        startTime: this.startdate,
        ...(this.enddate && { endTime: this.enddate }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectDevices,
          },
          country: {
            included: [],
          },
          ...(this.daypart && {
            daypart: {
              userTime: {
                included: this.daypart.split(','),
              },
            },
          }),
        },
        automatedKeywordsOptIn: false,
      };
      LSS.adGroup = adGroup;
      this.step = CampaignCreationSteps.SEARCH_MATCH;
    },

    async appendAudience() {
      const adamId = LSS.adamId;
      const allOwnedApps = this.appList.map((app) => app.adamId);
      const audience: IAudience = {
        // getting audience object set by adgroup screen
        ...LSS.adGroup.targetingDimensions,
        // age criteria
        ...(this.ageRange && {
          age: {
            included: [
              { minAge: this.ageRangeValue[0], maxAge: this.ageRangeValue[1] },
            ],
          },
        }),
        // gender criteria
        ...(this.selectedGender && {
          gender: {
            included: [this.selectedGender],
          },
        }),
        // App downloader criteria (customer type drop-down)
        ...(this.selectedCustomerType === CustomerTypes.NEW && {
          appDownloaders: {
            excluded: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
          appDownloaders: {
            included: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
          appDownloaders: {
            included: [...allOwnedApps.map((adamId) => adamId.toString())],
          },
        }),
        ...(this.selectedLocation && {
          locality: { included: this.selectedLocation },
        }),
      };
      LSS.adGroup = {
        pricingModel: 'CPC',
        ...(LSS.adGroup.defaultBidAmount && {
          defaultBidAmount: LSS.adGroup.defaultBidAmount,
        }),
        ...(LSS.adGroup.cpaGoal && { cpaGoal: LSS.adGroup.cpaGoal }),
        ...(LSS.adGroup.startTime && { startTime: LSS.adGroup.startTime }),
        ...(LSS.adGroup.endTime && { endTime: LSS.adGroup.endTime }),
        ...(LSS.adGroup.status && { status: LSS.adGroup.status }),
        ...(LSS.adGroup.orgId && { orgId: LSS.adGroup.orgId }),
        ...(LSS.adGroup.automatedKeywordsOptIn && {
          automatedKeywordsOptIn: LSS.adGroup.automatedKeywordsOptIn,
        }),
        ...(LSS.adGroup.name && { name: LSS.adGroup.name }),
        targetingDimensions: audience,
      };

      this.createCampaign();
    },

    async runMutation(mutationOptions: IMutationOptions) {
      return await this.$apollo.mutate({
        mutation: mutationOptions.mutationQuery,
        variables: mutationOptions.variables,
      });
    },

    setCountries(data) {
      if(data[0]) {
        const Countries = data[0].countryOrRegionCodes;
        for (const countryCode of Countries) {
          for (const country of this.Countries) {
            if (country.Code == countryCode) {
              this.appCountries.push(country);
            }
          }
        }
      } else {
        this.appCountries = this.Countries;
      }
    },
  },
  watch: {
    budget(amount) {
      this.$refs.dailyCapref.validate();
    },
    searchTabMetadata() {
      this.dialog = true;
    },
  
    search(query) {
      if(query != this.selectedApp?.appName)
        this.searchForApps(query);
    },
    selectedCountries(value) {
      //sonarqube
      /* value && value.length > 1
        ? ((this.areMultipleCountries = true), (this.selectedLocation = null))
        : ((this.areMultipleCountries = false),
          (this.locations = []),
          (this.selectedLocation = null)); */

      if(value && value.length > 1){
        this.areMultipleCountries = true;
        this.selectedLocation = null;
      }
      else{
        this.areMultipleCountries = false;
        this.locations = [];
        this.selectedLocation = null;
      }
    },

    orgId(value) {
      this.searchForApps('');
      // handling currency as per the org
      if (value)
        this.currency = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).currency;
    },
    searchLoc(query: string): void {
      query &&
        query !== this.selectedLocation &&
        this.selectedCountries.length === 1 &&
        query.length > 2 &&
        this.searchLocations(query, this.selectedCountries[0]);
    },

    step(current: number, previous: number): void {
      this.clickOutsideEnabled = current === 3;
    },
  },
  async created() {
    if (this.draftId) {
      this.$apollo.queries.campaignDraft.skip = false;
    }

    this.Countries = COUNTRIES;

    LSS.clearOther();
  },
});
