<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="642"
      persistent
    >
    <v-card elevation="3" class="  camp-view   rounded-lg">
       
              <!-- Tabs Open -->
              <v-row>
                
               <v-col cols="12" sm="12">
                  <v-card flat>
                    <v-card-text class="px-0 py-0">
                      <v-form class="px-3">
                      
                        <div class="sub-heading-with-btn mb-0  d-flex flex-column align-start sticky-top">
                            <h5 class=" primarybase--text h5--text-bold mb-3 w-100 d-flex justify-space-between">
                        <span> Add Campaign</span>  <v-icon class="d-flex justify-end primarybase--text close" @click="close(true)">mdi-close</v-icon>
                        </h5>
                        <p class=" primarybase--text body--text-1 mb-0">
                         {{ $t('createCampaign.select') }}
                        </p>
                         </div>
                        <v-radio-group v-model="radioGroup" class="mt-0">
                          <v-row class="mt-0">
                            <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                              <div :class="radioGroup==0?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  > 
                                <v-label block class="pb-4"></v-label> 
                                 <v-radio :value="0">
                                  <template v-slot:label>
                                    <div>
                                      <h4 class=" mb-3 h7--text-bold ">{{ $t('createCampaign.searchResults') }} </h4>
                                      <div class=" caption--text-bold">
                                        {{ $t('createCampaign.srData') }}
                                      </div>
                                    </div>
                                 <!-- <v-img
                                    src="../assets/images/result-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                              <div :class="radioGroup==1?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="1">
                                  <template v-slot:label>
                                    <div>
                                      <h4  class="h7--text-bold  mb-3" >{{ $t('createCampaign.searchTab') }} </h4>
                                      <div class="caption--text-bold">
                                        {{ $t('createCampaign.stData') }}
                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/search-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                              <div :class="radioGroup==2?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'" >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="2">
                                  <template v-slot:label>
                                    <div>
                                      <h4  class="h7--text-bold mb-3 ">{{ $t('createCampaign.todayTab') }} </h4>
                                      <div class="caption--text-bold">
                                          {{ $t('createCampaign.todayData') }}
                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/today-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                              <div :class="radioGroup==3?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'" >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="3">
                                  <template v-slot:label>
                                    <div>
                                      <h4  class="h7--text-bold mb-3 ">{{ $t('createCampaign.productPageTab') }}</h4>
                                      <div class="caption--text-bold">
                                           {{ $t('createCampaign.productPageData') }}
                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/product-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>

                       <!--   <v-row class="mt-0">
                            <v-col cols="12" sm="12">
                              <div class="camp-upload-file">
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="1">
                                  <template v-slot:label>
                                    <div>
                                      <strong>{{ $t('createCampaign.searchTab') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.stData') }}
                                      </div>
                                    </div>
                                  </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>-->
                        </v-radio-group>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              
              <v-row class="mb-0 sticky-bottom">
              <v-col cols="12" sm="12" class="text-right pt-0 pb-0">
                <v-btn color="disable" outlined class="mr-4 px-6 w-xsm-100 mb-2" @click="close(true)"   >
                {{ $t('createCampaign.cancel') }}</v-btn >
                <v-btn color="primary" elevation="0" class=" px-6 w-xsm-100 mb-2" @click=" openContiBtn() "   >
                {{ $t('createCampaign.continue') }}
                </v-btn  >
              </v-col>
            </v-row>

            </v-card>


    </v-dialog>
  </div>
</template>


<script>
import Vue from 'vue';
import { LS_DRAFT_KEY } from '@shared/config';
export default Vue.extend({
  name: 'CampaginViewDialog',
  props: {
    campaignViewMetadata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      radioGroup: 0,
      dialog:false,
    };
  },
  watch: {
    campaignViewMetadata() {
      this.dialog = true;
    },
  },
  methods: {
     close(flag = false) {
      if(flag){
        localStorage.removeItem(LS_DRAFT_KEY)
      }
      this.dialog = false;
    },
     openContiBtn() {
      // console.log("selectedRadioButton",this.radioGroup);
        this.$emit('emitNextStep',this.radioGroup);
        this.close();
      },
    selectCampaign() {
      if (this.radioGroup == 0) {
        return this.$router.push('/create-campaign');
      } else if (this.radioGroup == 1) {
        return this.$router.push('/searchtab-campaign');
      } else if (this.radioGroup == 2) {
        return this.$router.push('/todaystab-campaign');
      } else if (this.radioGroup == 3) {
        return this.$router.push('/productpage-campaign');
      }
    },

    back() {
     // return this.$router.push('/ads-manager');
    },
  },
});
</script>
